let serviceListVi = [
  {
    title: "Quản lý cơ sở dữ liệu",
    des: "Chuyên gia CSDL với giải pháp Oracle, MySQL, SQL Server, AWS, Azure, Google,..",
    img: "assets/img/service-icon/1.png",
    url: "/service-details"
  },
  {
    title: "Phát triển phần mềm",
    des: "Phần mềm tiên tiến, tùy chỉnh linh hoạt cùng đội ngũ lập trình tài năng.",
    img: "assets/img/service-icon/2.png",
    url: "/service-details-2"
  },
  {
    title: "Trí tuệ nhân tạo (AI)",
    des: "AI thông minh giúp khai thác dữ liệu, nâng cao hiệu quả và đưa ra quyết định tối ưu.",
    img: "assets/img/service-icon/3.png",
    url: "/service-details-3"
  },
  {
    title: "Quản lý mạng và bảo mật thông tin",
    des: "Quản lý mạng toàn diện, từ thiết lập bảo mật, đảm bảo thông tin và hoạt động kinh doanh.",
    img: "assets/img/service-icon/2.png",
    url: "/service-details-4"
  },
  {
    title: "Kiểm tra đảm bảo chất lượng",
    des: "Đảm bảo chất lượng và độ tin cậy cho sản phẩm của bạn trước khi ra mắt thị trường.",
    img: "assets/img/service-icon/1.png",
    url: "/service-details-5"
  },

  {
    title: "Quản lý dự án",
    des: "Quản lý dự án chuyên nghiệp, đảm bảo tiến độ, trong ngân sách và đạt chất lượng cao.",
    img: "assets/img/service-icon/3.png",
    url: "/service-details-6"
  },
];

let serviceListEn = [
  {
    title: "Database Administration",
    des: "Database experts with solutions like Oracle, MySQL, SQL Server, AWS, Azure, Google, etc.",
    img: "assets/img/service-icon/1.png",
    url: "/service-details"
  },
  {
    title: "Software Development",
    des: "Innovative, creative software solutions to propel your business forward effectively.",
    img: "assets/img/service-icon/2.png",
    url: "/service-details-2"
  },
  {
    title: "Artificial Intelligence (AI)",
    des: "Smart AI solutions harness data power, improve efficiency, and enable optimal decisions.",
    img: "assets/img/service-icon/3.png",
    url: "/service-details-3"
  },
  {
    title: "Network Management",
    des: "Network management from setup to security, ensuring business continuity and safety.",
    img: "assets/img/service-icon/2.png",
    url: "/service-details-4"
  },
  {
    title: "Quality Assurance Testing",
    des: "Guarantee product quality and reliability before it launches to the market successfully.",
    img: "assets/img/service-icon/1.png",
    url: "/service-details-5"
  },

  {
    title: "Project Management",
    des: "Professional project management, ensuring on-time, on-budget delivery with high quality.",
    img: "assets/img/service-icon/3.png",
    url: "/service-details-6"
  },
];

export default { vi: serviceListVi, en: serviceListEn };
