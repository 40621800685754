import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const options = [
    {
      value: "Tiếng Việt",
      label: (
          <img src="assets/img/flag/vn.png" width="20" alt="" />
      )
    },
    {
      value: "English",
      label: (
          <img src="assets/img/flag/uk.png" width="20" alt="" />
      )
    }
  ];

  function handleChangeLanguageClick(n) {
    if (n === 0) {
      i18n.changeLanguage("vi");
    } else {
      i18n.changeLanguage("en");
    }
  }

  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>
      {/* navbar start */}
      <nav className='navbar navbar-area navbar-expand-lg'>
        <div className='container nav-container navbar-bg'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo-kadi-1.svg' alt='img' />
            </Link>
          </div>
          <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              <li className='menu-item-has-children'>
                <a href='/'>{t('navbar.home')}</a>
              </li>
              <li className='menu-item-has-children'>
                <a href='/service'>{t('navbar.service')}</a>
              </li>
              <li className='menu-item-has-children'>
                <a href='#'>{t('navbar.pages')}</a>
                <ul className='sub-menu'>
                  {/*<li>*/}
                  {/*  <Link to='/about'>{t('navbar.about')}</Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <Link to='/team'>Team</Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <Link to='/team-details'>Team Details</Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to='/case-study-details'>{t('navbar.case_study')}</Link>
                  </li>
                </ul>
              </li>
              {/*<li className='menu-item-has-children'>*/}
              {/*  <a href='/blog'>Blog</a>*/}
              {/*</li>*/}
              <li>
                <Link to='/contact'>{t('navbar.contact_us')}</Link>
              </li>
            </ul>
          </div>
          {/*<div className='nav-right-part nav-right-part-desktop align-self-center'>*/}
          {/*  <a className='navbar-phone' href='tel:'>*/}
          {/*    <span className='icon'>*/}
          {/*      <img src='assets/img/icon/1.png' alt='img' />*/}
          {/*    </span>*/}
          {/*    <span>Need help?</span>*/}
          {/*    <h5>(+84) 3996 9995</h5>*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className='select-language nav-right-part nav-right-part-desktop align-self-center mr-20'>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                  className=" bg-transparent btn-outline-secondary border-0 "
                  id="lng-dropdown"
              >
                {i18n.language === 'vi' ? options[0].label : options[1].label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleChangeLanguageClick(0)}>
                  {options[0].value}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleChangeLanguageClick(1)}>
                  {options[1].value}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
