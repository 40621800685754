import React from "react";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import ServiceDetailsArea3 from "../components/ServiceDetailsArea3";

const ServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      {/*<Breadcrumb title={"Service Details"} />*/}

      {/* Service Details Area */}
      <ServiceDetailsArea3 />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default ServiceDetails;
