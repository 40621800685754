import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ServiceDetailsArea = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight/> All Service lists
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details'> {t('service_detail_1.title0')}</Link>
                    </li>
                    <li>
                      <Link to='/service-details-2'> {t('service_detail_2.title0')}</Link>
                    </li>
                    <li>
                      <Link to='/service-details-3'> {t('service_detail_3.title0')}</Link>
                    </li>
                    <li>
                      <Link to='/service-details-4'> {t('service_detail_4.title0')}</Link>
                    </li>
                    <li className={"a-active"}>
                      <Link to='/service-details-5'> {t('service_detail_5.title0')}</Link>
                    </li>
                    <li>
                      <Link to='/service-details-6'> {t('service_detail_6.title0')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/6304ea816823cf0a4b06f777_what-is-testing.jpg' alt='img'/>
                  </div>
                  <div className='details'>
                    <h4 dangerouslySetInnerHTML={{__html: t('service_detail_5.title')}}></h4>
                    <p dangerouslySetInnerHTML={{__html: t('service_detail_5.param1')}}></p>
                    <p dangerouslySetInnerHTML={{__html: t('service_detail_5.param2')}}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
