import React from "react";
import { FaArrowRight } from "react-icons/fa";
import {useTranslation} from "react-i18next";

const CaseStudyArea = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* ====================  Case Study Area start ====================*/}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight/> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>WMC</p>
                    <h6>Category</h6>
                    <p>It Service</p>
                    <h6>Date</h6>
                    <p>2022</p>
                    <h6>Location</h6>
                    <p>Ho Chi Minh city</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/63fe62730bebcd5e512ca06b_image-4-1.webp' alt='img'/>
                  </div>
                  <div className='details'>
                    <h4>
                      {t('case_study.title_1')}
                    </h4>
                    <p>
                      {t('case_study.content_1')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight/> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>Embassy Education</p>
                    <h6>Category</h6>
                    <p>It Service</p>
                    <h6>Date</h6>
                    <p>2023</p>
                    <h6>Location</h6>
                    <p>Ho Chi Minh city</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/UOAMusic_Website_Banner_Banner_Home-EN-1.png' alt='img'/>
                  </div>
                  <div className='details'>
                    <h4>
                      {t('case_study.title_2')}
                    </h4>
                    <p>
                      {t('case_study.content_2')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight/> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>Yoga Planet</p>
                    <h6>Category</h6>
                    <p>It Service</p>
                    <h6>Date</h6>
                    <p>2023</p>
                    <h6>Location</h6>
                    <p>Ho Chi Minh city</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/Untitled-3-05.png' alt='img'/>
                  </div>
                  <div className='details'>
                    <h4>
                      {t('case_study.title_4')}
                    </h4>
                    <p>
                      {t('case_study.content_4')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_info'>
                  <h5 className='widget-title'>
                    <FaArrowRight/> Project Info
                  </h5>
                  <div className='widget-info-inner'>
                    <h6>Clients</h6>
                    <p>Dreams Pass</p>
                    <h6>Category</h6>
                    <p>It Service</p>
                    <h6>Date</h6>
                    <p>2023</p>
                    <h6>Location</h6>
                    <p>Ho Chi Minh city</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src='assets/img/service/image_processing20200629-15403-fovkpe.jpg' alt='img'/>
                  </div>
                  <div className='details'>
                    <h4>
                      {t('case_study.title_3')}
                    </h4>
                    <p>
                      {t('case_study.content_3')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Case Study Area End ====================*/}
    </>
  );
};

export default CaseStudyArea;
